import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { HomepageTypes } from '@shared/types/umbraco/content/homepage';

import Layout from 'layout/Layout';
import DescriptionLinkVideo from 'common/DescriptionLinkVideo';
import ThanksgivingBanner from 'components/ThanksgivingBanner';
import HomeBanner from 'components/HomeBanner';
import InfoBanner from 'components/InfoBanner';
import WideBanner from 'components/WideBanner';
import BodyRenderer from 'common/BodyRenderer';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import SlimBanner from 'components/SlimBanner';
import ProductListingBanner from 'components/ProductListingBanner';
import ProductListingBannerCarousel from 'components/ProductListingBannerCarousel';
import SignUp from 'components/SignUp';
import Quiz from 'components/Quiz';
import ImageBanner from 'components/ImageBanner';
import WithCarousel from 'components/WithCarousel';
import CommunityConnectSection from 'components/CommunityConnectSection';
import BestsellersList from 'components/BestsellersList';
import MediaBanner from 'components/MediaBanner';
import ImageWithPromoForm from 'components/ImageWithPromoForm';
import FeatureBanner from 'components/FeatureBanner';

import './HomePage.scss';

const elements = {
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Image Banner': ({ properties }, keyId) => <ImageBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => (
    <WideBanner key={keyId} {...properties} buttons={properties?.cta} />
  ),
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Quiz Placeholder': ({ properties }, keyId) => <Quiz key={keyId} {...properties} />,
  'Section with carousel': ({ properties }, keyId) => {
    const {
      text,
      card,
      color,
      carouselMode,
      button,
      waiSettings,
      verticalCardMode,
      fullwidthCardMode,
    } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
              isVerticalCardMode={verticalCardMode}
              isFullwidthCardMode={fullwidthCardMode}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} isVerticalCardMode={verticalCardMode} />
          )
        }
        button={button}
      />
    );
  },
  'Description Link Video': ({ properties }, keyId) => (
    <DescriptionLinkVideo key={keyId} {...properties} />
  ),
  'Product Listing With Carousel': ({ properties }, keyId) => (
    <ProductListingBannerCarousel key={keyId} {...properties} />
  ),
  'Home Banner': ({ properties }, keyId) => <HomeBanner key={keyId} {...properties} />,
  'Community Connect Section': ({ properties }, keyId) => (
    <CommunityConnectSection key={keyId} {...properties} />
  ),
  'Bestsellers List': ({ properties }, keyId) => <BestsellersList key={keyId} {...properties} />,
  'New Media Banner': ({ properties }, keyId) => <MediaBanner key={keyId} {...properties} />,
  'Image With Promo Form': ({ properties }, keyId) => (
    <ImageWithPromoForm key={keyId} {...properties} />
  ),
  'Feature Banner': ({ properties }, keyId) => <FeatureBanner key={keyId} {...properties} />,
};

const HomePage: FC<{ data: HomepageTypes.IProperties }> = ({ data, pathContext }) => {
  const banner = data?.homepage?.banner?.map(({ properties, structure }) => ({
    ...properties,
    structure,
  }));
  const body = data?.homepage?.body;
  const relatedProducts = data?.productBundles;
  const carouselProducts = data?.productsCarousel;
  const {
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaTitleTemplate,
    seoMetaDescription,
    seoExternalHreflangs,
    seoNoIndex,
    alternateUrls,
    ogImageUrl,
  } = data?.homepage;
  const {
    siteSettings,
    brandSettings,
    header,
    footer,
    waiSettings,
    purchaseSettings,
    warning,
    signUpPopup,
    signUpFormPopup,
  } = data?.homepage?.defaultCompositions;
  const singupFormBaner = data?.homepage?.defaultCompositions?.singupFormBaner;
  const isShopify = data?.homepage?.defaultCompositions?.siteSettings?.isShopify;
  const lang = data?.homepage?.defaultCompositions?.siteSettings?.lang;
  const productsLinks = pathContext?.productBundlesLinks || [];
  const productsCarouselLinks = pathContext?.productCarouselLinks || [];
  const quizData = data?.quizData?.nodes?.[0];
  const sortedProducts = productsLinks
    .map((el) => relatedProducts?.nodes?.find((prodItem) => prodItem.link === el))
    .filter(Boolean);
  const sortedCarouselProducts = productsCarouselLinks
    .map((el) => carouselProducts?.nodes?.find((prodItem) => prodItem.link === el))
    .filter(Boolean);
  const { productBundles } = data;
  const imageUrl = ogImageUrl || banner?.[0]?.background?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.background?.[0]?.properties?.imageAlt;

  return (
    <Layout
      {...{
        footer,
        header,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        siteSettings,
        brandSettings,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        warning,
        link: pathContext.link,
        searchUrl: pathContext.searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
        signUpPopup,
        singupFormBaner,
        signUpFormPopup,
      }}
    >
      <h1 className="visual-hidden">{seoMetaTitle}</h1>
      {data?.homepage?.thanksgivingBanner?.length ? (
        <ThanksgivingBanner
          title={data.homepage.thanksgivingBanner[0].properties.title}
          cta={data.homepage.thanksgivingBanner[0].properties.cta}
          description={data.homepage.thanksgivingBanner[0].properties.description}
          image={data.homepage.thanksgivingBanner[0].properties.image}
          icon={data.homepage.thanksgivingBanner[0].properties.icon}
          background={data.homepage.thanksgivingBanner[0].properties.background}
          mobileBackground={data.homepage.thanksgivingBanner[0].properties.mobileBackground}
        />
      ) : null}
      <WithCarousel
        slides={banner}
        slideComponent={HomeBanner}
        ariaNext={waiSettings.ariaNext}
        ariaPrev={waiSettings.ariaPrev}
        isRtl={siteSettings?.isRTL}
      />
      {body?.length ? (
        <div className="home__body">
          <BodyRenderer
            bodyData={body}
            bodyStructure={elements}
            bodyItemProps={{
              relatedProducts: { nodes: sortedProducts },
              carouselProducts: { nodes: sortedCarouselProducts },
              waiSettings,
              singupFormBaner,
              isShopify,
              lang,
              cartProductsData: productBundles,
              quizData,
              usePriceSpider: siteSettings?.usePriceSpider,
              isEanProductId: siteSettings?.isEanProductId,
            }}
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query HomePage(
    $productBundlesLinks: [String]
    $link: String = ""
    $lang: String = ""
    $productCarouselLinks: [String]
  ) {
    homepage(link: { eq: $link }) {
      seoMetaTitle
      seoMetaTitleTemplate
      seoMetaDescription
      seoMetaKeywords
      seoNoIndex
      seoExternalHreflangs {
        key
        value
      }
      alternateUrls {
        lang
        url
        path
      }
      ogImageUrl
      defaultCompositions {
        footer {
          ...FragmentFooter
        }
        header {
          ...FragmentHeader
        }
        waiSettings {
          skipText
          ariaPrev
          ariaNext
        }
        siteSettings {
          ...FragmentSiteSettingsComposition
        }
        brandSettings {
          ...FragmentBrandSettingsComposition
        }
        singupFormBaner {
          ...FragmentSignUpBanner
        }
        purchaseSettings {
          ...FragmentPurchaseSettings
        }
        warning {
          ...FragmentWarning
        }
        signUpPopup {
          ...FragmentSignUpPopup
        }
        signUpFormPopup {
          ...FragmentSignUpFormPopup
        }
      }
      thanksgivingBanner {
        ...FragmentThanksgivingBanner
      }
      banner {
        ...FragmentHomeBanner
      }
      body {
        ... on TImageBanner {
          ...FragmentImageBanner
        }
        ... on TInfoBanner {
          ...FragmentInfoBanner
        }
        ... on TInfoBannerWide {
          ...FragmentWideBanner
        }
        ... on TSectionwithcarousel {
          ...FragmentSectionWithCarousel
        }
        ... on TSlimbanner {
          ...FragmentSlimBanner
        }
        ... on TProductListingBanner {
          ...FragmentProductListingBanner
        }
        ... on TSignupPlaceholder {
          ...FragmentSignUp
        }
        ... on TQuizPlaceholder {
          ...FragmentQuiz
        }
        ... on TDescriptionLinkVideo {
          ...FragmentDescriptionLinkVideo
        }
        ... on TProductListingWithCarousel {
          ...FragmentProductListingWithCarousel
        }
        ... on THomeBanner {
          ...FragmentHomeBanner
        }
        ... on TCommunityConnectSection {
          ...FragmentCommunityConnectSection
        }
        ... on TBestsellersList {
          ...FragmentBestsellersList
        }
        ... on TNewMediaBanner {
          ...FragmentMediaBanner
        }
        ... on TImageWithPromoForm {
          ...FragmentImageWithPromoForm
        }
        ... on TFeatureBanner {
          ...FragmentFeatureBanner
        }
      }
    }
    productBundles: allBundle(filter: { link: { in: $productBundlesLinks } }) {
      nodes {
        useRedirects
        productInfo
        link
        tags {
          isCategory
          color {
            label
          }
          isLabel
          title
        }
        bundleProducts {
          link
          quantity
        }
        title
        shopifyId
        skuId
        ean
        bundleProductsQuantity
        shortDescription
        cardDescription
        images: imageForCart {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200, srcSetBreakpoints: [100, 400]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        cardImage {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    quizData: allQuiz(filter: { lang: { eq: $lang } }) {
      nodes {
        pageName
        link
        quizResult {
          properties {
            resultDescription
            restart
            ariaRestart
            limit
          }
        }
        relatedSlides {
          url
          properties {
            step
            question
            options {
              structure
              properties {
                description
                icon
                nextSlide {
                  url
                }
                isDirectLink
                tags {
                  title
                }
                title
              }
            }
          }
        }
      }
    }
    productsCarousel: allProduct(filter: { link: { in: $productCarouselLinks } }) {
      nodes {
        useRedirects
        position
        link
        ean
        skuId
        productVariantsShopifyData {
          skuId
          shopifyId
        }
        tags {
          isFamily
          isLabel
          title
          color {
            label
          }
        }
        cardLink {
          url
        }
        title
        productSize
        productScent
        productType
        shortDescription
        cardDescription
        lang
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 360, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        cardImage {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 360, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
